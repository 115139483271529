import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '@/views/Main'

Vue.use(VueRouter)

const routes = [
    {
        path: "/login",
        name: "login",
        component: () => import("@/views/Login"),
    },
    {
        path: "/",
        name: "Main",
        component: Main,
        redirect: "/dashboard",
        children: [{
            path: "dashboard",
            component: () => import("@/views/Dashboard"),
            name: "数据看板",
        }]
    },
    {
        path: "/unauthorized",
        name: "unauthorized",
        component: () => import("@/views/Unauthorized"),
    },
    {
        path: '/',
        name: '消息中心',
        component: Main,
        children: [{
            path: '/message/list',
            component: () => import("@/views/message/list"),
            name: '消息列表'
        }]
    },
    {
        path: '/',
        name: '审批待办',
        component: Main,
        children: [{
            path: '/backlog/list',
            component: () => import("@/views/backlog/list"),
            name: '审批待办列表'
        }]
    },
    {
        path: '/',
        name: '简历管理',
        component: Main,
        children: [{
            path: '/resume/list',
            component: () => import("@/views/resume/list"),
            name: '简历列表'
        }]
    },
    {
        path: "/resource",
        component: Main,
        name: "客户管理",
        children: [{
            path: "customer/list",
            component: () => import("@/views/resource/customer/list"),
            name: "客户列表",
        },{
            path: "opportunity/list",
            component: () => import("@/views/resource/opportunity/list"),
            name: "商机列表",
        }]
    },
    {
        path: '/position',
        name: '职位管理',
        component: Main,
        children: [{
            path: 'mgt/list',
            component: () => import("@/views/position/mgt/list"),
            name: '职位列表'
        },{
            path: "share/list",
            component: () => import("@/views/position/share/list"),
            name: "共享列表",
        }]
    },
    {
        path: '/',
        name: '目标管理',
        component: Main,
        children: [{
            path: '/goal/list',
            component: () => import("@/views/goal/list"),
            name: '目标列表'
        }]
    },
    {
        path: "/report",
        component: Main,
        name: "统计报表",
        children: [{
            path: "conclude/list",
            component: () => import("@/views/report/conclude/list"),
            name: "目标达成",
        },{
            path: "sales/list",
            component: () => import("@/views/report/sales/list"),
            name: "销售商机",
        },{
            path: "delivery/list",
            component: () => import("@/views/report/delivery/list"),
            name: "职位交付过程",
        },{
            path: "resumes/list",
            component: () => import("@/views/report/resumes/list"),
            name: "简历数据",
        },{
            path: "customer/list",
            component: () => import("@/views/report/customer/list"),
            name: "客户数据",
        }]
    },
    {
        path: '/',
        name: '文档管理',
        component: Main,
        children: [{
            path: '/document/list',
            component: () => import("@/views/document/list"),
            name: '文档列表'
        }]
    },
    {
        path: "/wechat",
        component: Main,
        name: "小程序管理",
        children: [{
            path: "wcSlideshow/list",
            component: () => import("@/views/wechat/wcSlideshow/list"),
            name: "幻灯列表",
        },{
            path: "wcResume/list",
            component: () => import("@/views/wechat/wcResume/list"),
            name: "模板列表",
        },{
            path: "wcCustomer/list",
            component: () => import("@/views/wechat/wcCustomer/list"),
            name: "企业列表",
        },{
            path: "wcPosition/list",
            component: () => import("@/views/wechat/wcPosition/list"),
            name: "职位列表",
        },{
            path: "wcClassify/list",
            component: () => import("@/views/wechat/wcClassify/list"),
            name: "分类列表",
        },{
            path: "wcQuestion/list",
            component: () => import("@/views/wechat/wcQuestion/list"),
            name: "题库列表",
        },{
            path: "wcInfo/list",
            component: () => import("@/views/wechat/wcInfo/list"),
            name: "咨询列表",
        }]
    },
    {
        path: "/system",
        component: Main,
        name: "系统管理",
        children: [{
            path: "user/list",
            component: () => import("@/views/system/user/list"),
            name: "用户列表",
        },{
            path: "role/list",
            component: () => import("@/views/system/role/list"),
            name: "角色列表",
        },{
            path: "dept/list",
            component: () => import("@/views/system/dept/list"),
            name: "部门列表",
        },{
            path: "msgs/list",
            component: () => import("@/views/system/msgs/list"),
            name: "系统消息列表",
        },{
            path: "loginLog/list",
            component: () => import("@/views/system/loginLog/list"),
            name: "登录日志列表",
        },{
            path: "operationLog/list",
            component: () => import("@/views/system/operationLog/list"),
            name: "操作日志列表",
        },{
            path: "permissions/list",
            component: () => import("@/views/system/permissions/list"),
            name: "权限列表",
        },{
            path: "dictionary/list",
            component: () => import("@/views/system/dictionary/list"),
            name: "数据字典",
        }]
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {
    if (to.path.startsWith('/login')) {
        window.sessionStorage.removeItem("menuItem");
        window.sessionStorage.removeItem("submenu");
        window.sessionStorage.removeItem('Authorization');
        next();
    } else {
        let Authorization = window.sessionStorage.getItem('Authorization');
        if (Authorization) {
            next();
        } else {
            next({path: '/login'});
        }
    }
});

export default router
