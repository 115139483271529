/**
 * Created by TapFun on 2022/03/04.
 */
import qs from 'qs';
import {SERVER_HOST} from '../common/portConfig';
import request from '@/common/request'

// 获取图片验证码：
export const reqCodeImg = params => {
    return request.get(`/getCodeImage`, {
        params: params,
        responseType: 'blob'
    })
};
// 登录
export const reqSystemLogin = params => {
    return request.post(`/login`, qs.stringify(params)).then(res => res.data)
};
// 左侧菜单列表
export const reqGetLeftMenus = params => {
    return request.get(`/permission/leftMenu`, {
        params: params
    })
};
// 角色分页
export const reqGetRoleListPage = params => {
    return request.get(`/role/pageQuery`, {
        params: params
    })
};
//添加角色
export const reqAddRole = params => {
    return request.post(`/role/add`, qs.stringify(params))
};
//编辑角色
export const reqEditRole = params => {
    return request.post(`/role/edit`, qs.stringify(params))
};
//删除角色
export const reqDeleteRole = params => {
    return request.get(`/role/del`, {
        params: params
    })
};
// 根据id查询角色信息
export const reqGetRoleById = params => {
    return request.get(`/role/queryById`, {
        params: params
    })
};
// 获取角色列表
export const reqGetRoleList = params => {
    return request.get(`/role/list`, {
        params: params
    })
};
// 用户修改密码
export const updateUserPwd = params => {
    return request.post(`/user/updatePwd`, params)
};
// 用户列表
export const reqGetUserListPage = params => {
    return request.get(`/user/page`, {
        params: params
    })
};
// 添加用户
export const reqAddUser = params => {
    return request.post(`/user/add`, params)
};
// 用户禁用/启用
export const reqEditUserStatus = params => {
    return request.post(`/user/editStatus`, qs.stringify(params))
};
// 通过id获取用户信息
export const reqGetUserById = params => {
    return request.get(`/user/one`, {
        params: params
    })
};
// 编辑用户
export const reqEditUserData = params => {
    return request.post(`/user/edit`, params)
};
// 删除用户
export const reqDeleteUser = params => {
    return request.get(`/user/del`, {
        params: params
    })
};
// 获取用户列表
export const reqGetUserList = params => {
    return request.get(`/user/list`, {
        params: params
    })
};
// 权限列表
export const queryPermissions = params => {
    return request.get(`/permission/queryPermissions`, {
        params: params
    })
};
// 按钮权限
export const reqGetPermissionButton = params => {
    return request.get(`/permission/queryPermissionButton`, {
        params: params
    })
};
// 权限编辑
export const reqEditRolePermission = params => {
    return request.post(`/role/updatePermission`, qs.stringify(params))
};
// 数据字典列表
export const reqGetDictionaryList = params => {
    return request.get(`/dictionary/list`, {
        params: params
    })
};
// 数据字典根节点分页查询
export const reqGetDictionaryPageList = params => {
    return request.get(`/dictionary/pageList`, {
        params: params
    })
};
// 根据父级id查询所有子节点
export const reqGetDictionaryChildren = params => {
    return request.get(`/dictionary/children`, {
        params: params
    })
};
// 通过code查询vue-treeselect选项列表
export const reqGetTreeOptionsByCode = params => {
    return request.get(`/dictionary/treeOptions`, {
        params: params
    })
};
// 数据字典父级列表
export const reqGetDictionaryParentList = params => {
    return request.get(`/dictionary/getParentList`, {
        params: params
    })
};
// 数据字典添加
export const reqAddDictionary = params => {
    return request.post(`/dictionary/add`, params)
};
// 数据字典删除
export const reqDeleteDictionary = params => {
    return request.delete(`/dictionary/delete/` + params.id, params)
};
// 通过code查询可用的数据字典选项
export const reqGetDictionaryListByCode = params => {
    return request.get(`/dictionary/getListByCode`, {
        params: params
    })
};
// 通过code查询所有数据字典选项
export const reqGetAllDictionaryListByCode = params => {
    return request.get(`/dictionary/getAllListByCode`, {
        params: params
    })
};
// 通过id获取数据字典信息
export const reqGetDictionaryOne = params => {
    return request.get(`/dictionary/one`, {
        params: params
    })
};
// 数据字典编辑
export const reqEditDictionary = params => {
    return request.post(`/dictionary/edit`, params)
};
// 日志列表
export const reqGetLogListPage = params => {
    return request.get(`/loginLog/page`, {
        params: params
    })
};
// 日志
export const reqGetOperationLogList = params => {
    return request.get(`/operationLog/page`, {
        params: params
    })
};
// 文件上传
export const uploadFileURL = `${SERVER_HOST}/uploadFile`;
// 文件删除
export const reqDeleteFiles = params => {
    return request.post(`/deleteFiles`, params)
};
// 文件下载，需提供token
export const downloadFile = params => {
    return request.get(`/downloadFile`, {
        params: params,
        responseType: 'blob'
    })
};
// 通过code列表查询可用的数据字典选项map
export const reqDictionaryMapByCodeList = params =>{
    return request.post(`/dictionary/getMapByCodeList`, params)
}
