import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ViewUI from 'view-design';
import ZkTable from 'vue-table-with-tree-grid';
import moment from 'moment';
import * as echarts from "echarts";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

/*右边工具栏样式*/
import "babel-polyfill";

// import style
import 'view-design/dist/styles/iview.css';
import './index.less';


Vue.use(ViewUI);
Vue.use(ZkTable);
Vue.use(Loading);

Vue.config.productionTip = false;
Vue.prototype.$moment = moment;
Vue.prototype.$echarts = echarts;

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
