/**
 * Created by TapFun on 2022/11/08.
 */
import {reqGetPermissionButton} from '../api/system-api'

export default {
    getPermissionButton(permissionId, permissionButton) {
        reqGetPermissionButton({ permissionId: permissionId }).then((res) => {
            let buttonArray = res.data.data?.map(item => item.permissionUrl) || [];

            Object.keys(permissionButton)?.forEach(key => {
                permissionButton[key] = buttonArray.includes(key);
            })
        })
    }
}
