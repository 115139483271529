/**
 * Created by TapFun on 2023/02/21.
 */
import qs from 'qs';
import request from '@/common/request'

// 获取部门树列表
export const reqGetDepartmentTreeOpts = params =>{
    return request.get(`department/treeOpts`, {
        params: params
    })
}
// 获取可用的父级部门树列表
export const reqGetAvailParentDeptOpts = params =>{
    return request.get(`department/parentOpts`, {
        params: params
    })
}
// 根据id查询部门分类及其下级部门列表
export const reqGetChildListById = params =>{
    return request.get(`/department/childListById`,{
        params: params
    })
}
// 部门分类删除
export const reqDelDepartment = params => {
    return request.get(`/department/del`,{
        params: params
    })
};
// 部门分类新增
export const reqAddDepartment = params =>{
    return request.post(`/department/add`,params)
};
// 资产分类编辑
export const reqEditDepartment = params =>{
    return request.post(`/department/edit`,params)
}
// 根据条件查询资产分类列表
export const reqGetDepartmentByFilters = params =>{
    return request.get(`/department/byFilters`,{
        params: params
    })
}
// 通知中心分页查询
export const reqMessagePage = params =>{
    return request.get(`message/page`, {
        params: params
    })
}
// 系统通知分页查询
export const reqUserMessagePage = params =>{
    return request.get(`message/userPage`, {
        params: params
    })
}
// 通知中心已读
export const reqEditStatus = params =>{
    return request.post(`/message/editStatus`,params)
}
// 系统通知发布消息
export const reqPublish = params =>{
    return request.post(`/message/publish`,params)
}
// 通知删除
export const reqDeleteMessage = params => {
    return request.delete(`/message/delete/` + params.id, params)
};
// 新增通知
export const reqAddMessage = params =>{
    return request.post(`message/add`,params)
}
// 根据id查询通知信息
export const reqGetMessageById = params => {
    return request.get(`message/getMessageById`, {
        params: params
    })
};
// 编辑职位
export const reqEditMessage = params =>{
    return request.post(`message/edit`,params)
}
//  客户管理分页查询
export const reqGetCustomer = params =>{
    return request.get(`customer/page`, {
        params: params
    })
}
// 新增客户
export const reqAddCustomer = params =>{
    return request.post(`customer/add`,params)
}
// 根据id查询客户
export const reqGetCustomerById = params => {
    return request.get(`customer/getCustomerById`, {
        params: params
    })
};
// 编辑客户
export const reqEditCustomer = params =>{
    return request.post(`customer/edit`,params)
}
// 通知删除
export const reqDeleteCustomer = params => {
    return request.delete(`/customer/delete/` + params.id, params)
};
// 标注客户
export const reqMarkCustomer = params =>{
    return request.post(`customer/mark`,params)
}
// 交接客户
export const reqHandoverCustomer = params =>{
    return request.post(`customer/handover`,params)
}
// 文档管理分页查询
export const reqGetDocument = params =>{
    return request.get(`document/page`, {
        params: params
    })
}
// 新增文档
export const reqAddDocument = params =>{
    return request.post(`document/add`,params)
}
// 删除文档
export const reqDeleteDocument = params => {
    return request.delete(`/document/delete/` + params.id, params)
};
// 根据id查询客户
export const reqGetDocumentById = params => {
    return request.get(`document/getDocumentById`, {
        params: params
    })
};
// 编辑文档
export const reqEditDocument = params =>{
    return request.post(`document/edit`,params)
}
// 批量删除文档
export const reqDocumentBatchDel = params =>{
    return request.post(`document/batchDel`, {
        params: params
    })
};
// 消息列表查看分页
export const reqGetMessageRecipient = params =>{
    return request.get(`messageRecipient/page`, {
        params: params
    })
}
// 获取用户及其下属负责的客户列表
export const reqGetCustomerPerUser = params => {
    return request.get(`/customer/perUser`, {
        params: params
    })
};
// 获取所有客户列表
export const reqGetAllCustomer = params => {
    return request.get(`/customer/all`, {
        params: params
    })
};
// 新增职位
export const reqAddPosition = params =>{
    return request.post(`position/add`,params)
}
// 职位管理分页查询
export const reqGetPosition = params =>{
    return request.get(`position/page`, {
        params: params
    })
}
// 删除职位
export const reqDeletePosition = params => {
    return request.delete(`/position/delete/` + params.id, params)
};
// 批量删除职位
export const reqPositionBatchDel = params =>{
    return request.post(`/position/batchDel`, {
        params: params
    })
};
// 根据id查询职位
export const reqGetPositionById = params => {
    return request.get(`position/one`, {
        params: params
    })
};
// 编辑职位
export const reqEditPosition = params =>{
    return request.post(`position/edit`,params)
}
// 更新职位
export const reqUpdatePosition = params =>{
    return request.post(`position/update`,params)
}
// 职位放入共享池
export const reqPutToPool = params =>{
    return request.post(`position/putToPool`,params)
}
// 查询可用的顾问列表
export const reqGetAvailConsultants = params => {
    return request.get(`position/availConsultants`, {
        params: params
    })
};
// 查询加入到共享池的顾问列表
export const reqGetShareConsultants = params => {
    return request.get(`position/getShareConsultants`, {
        params: params
    })
};
// 共享池
export const reqSharePosition = params =>{
    return request.post(`position/share`,params)
}
// 管理共享池
export const reqChangeSharePosition = params =>{
    return request.post(`position/sharePosition`,params)
}
// 变更职位
export const reqChangePosition = params =>{
    return request.post(`position/change`,params)
}
// 根据职位id、顾问id、类型查询简历列表
export const reqGetResumesInDelivery = params => {
    return request.get(`deliveryResume/resumesInDelivery`, {
        params: params
    })
};
// 查询职位可用的简历列表
export const reqGetAvailResumes = params => {
    return request.get(`position/availResumes`, {
        params: params
    })
};
// 根据职位id查询当前登录用户进行中的交付信息
export const reqGetOpenDelivery = params => {
    return request.get(`position/openDelivery`, {
        params: params
    })
};
// 根据职位id查询当前登录用户进行中的交付信息
export const reqGetApprovalHistoryByDeliveryId = params => {
    return request.get(`deliveryApprovalHistory/history`, {
        params: params
    })
};
// 职位交付保存
export const reqSubmitDelivery = params =>{
    return request.post(`position/submit`,params)
}
// 查询当前登录用户可查看的所有交付
export const reqGetAvailDeliveries = params => {
    return request.get(`position/availDelivery`, {
        params: params
    })
};
// 根据id查询交付详情
export const reqGetDeliveryDetail = params => {
    return request.get(`delivery/detail`, {
        params: params
    })
};
// 根据交付审批历史信息id查询交付详情
export const reqGetDeliveryDetailByDahId = params => {
    return request.get(`delivery/detailByDahId`, {
        params: params
    })
};
// 审批待办查看分页
export const reqGetBackLog = params =>{
    return request.get(`backlog/page`, {
        params: params
    })
}
// 审批待办 - 审批
export const reqProcessBacklog = params =>{
    return request.post(`backlog/process`, params)
}
// 简历分页查询
export const reqGetResume = params =>{
    return request.get(`resume/page`, {
        params: params
    })
}
// 简历新增
export const reqAddResume = params =>{
    return request.post(`/resume/add`,params)
}
// 获取组织(包含部门人员)树列表
export const reqGetDepartmentOrgOpts = params => {
    return request.get(`department/orgOpts`, {
        params: params
    })
}
// 标注简历
export const reqMarkResume = params =>{
    return request.post(`resume/mark`,params)
}
// 根据id查询简历详情
export const reqGetResumeById = params =>{
    return request.get(`resume/one`, {
        params: params
    })
}
// 简历编辑
export const reqEditResume = params =>{
    return request.post(`/resume/edit`,params)
}
// 查询当前用户是顾问的可推荐职位列表
export const reqGetRecommendPositionList = params =>{
    return request.get(`position/recommendList`, {
        params: params
    })
}
// 查询当前登录用户是顾问且进行中的职位（面试、入职类型）列表。如果传入的简历id已推荐，则不包含该职位
export const reqGetRecommendPositionListByResumeId = params =>{
    return request.get(`position/recommendListByResumeId`, {
        params: params
    })
}
// 简历批量推荐
export const reqBatchRecommendResume = params =>{
    return request.post(`/resume/batchRecommend`,params)
}
// 删除简历
export const reqDeleteResume = params => {
    return request.delete(`/resume/delete/` + params.id, params)
};
// 批量删除简历
export const reqResumeBatchDel = params =>{
    return request.post(`resume/batchDel`, {
        params: params
    })
};
// 简历批量标记
export const reqBatchMarkResume = params =>{
    return request.post(`/resume/batchMark`,params)
}
// 简历推荐
export const reqRecommendResume = params =>{
    return request.post(`/resume/recommend`,params)
}
// 根据id查询推荐信息
export const reqGetPositionByResumeId = params => {
    return request.get(`resumePosition/getPositionByResumeId`, {
        params: params
    })
};
// 导入简历
export const reqImportResume = params =>{
    return request.post(`resume/import`, params)
}
// 导出简历
export const reqExportResume = params => {
    return request.post(`resume/export`, params, {responseType: 'blob'})
};
// 导出简历
export const reqBatchExportResume = params => {
    return request.post(`resume/batchExport`, {params:params}, {responseType: 'blob'})
};
// 商机管理查看分页
export const reqGetOpportunity = params =>{
    return request.get(`opportunity/page`, {
        params: params
    })
}
// 新增商机
export const reqAddOpportunity = params =>{
    return request.post(`opportunity/add`,params)
}
// 根据id查询推荐信息
export const reqGetOpportunityById = params => {
    return request.get(`opportunity/getOpportunityById`, {
        params: params
    })
};
// 新增商机
export const reqEditOpportunity = params =>{
    return request.post(`opportunity/edit`,params)
}
// 商机状态修改
export const reqEditOpportunityStatus = params => {
    return request.post(`opportunity/editStatus`, qs.stringify(params))
};
// 提审商机
export const submitOpportunity = params =>{
    return request.post(`opportunity/submitOpportunity`,params)
}
// 提审商机成本
export const reqReviewCost = params =>{
    return request.post(`opportunity/reviewCost`,params)
}
// 提审商机业绩
export const reqReviewPerformance = params =>{
    return request.post(`opportunity/reviewPerformance`,params)
}
// 通过商机id获取录入成本审批进度
export const reqGetEnterCostScheduleById = params => {
    return request.get(`opportunityApprovalHistory/reqGetEnterCostScheduleById`, {
        params: params
    })
};
// 通过商机id获取录入业绩审批进度
export const reqGetEnterPerformanceScheduleById = params => {
    return request.get(`opportunityApprovalHistory/reqGetEnterPerformanceScheduleById`, {
        params: params
    })
};
// 商机状态开启
export const reqOpenOpportunity = params => {
    return request.post(`opportunity/openOpportunity`, qs.stringify(params))
};
// 删除商机
export const reqDelOppOpportunity = params => {
    return request.delete(`/opportunity/delete/` + params.id, params)
};
// 目标管理分页查询
export const reqGetGoal = params =>{
    return request.get(`goal/page`, {
        params: params
    })
}
// 检查目标是否可提审
export const reqCheckIfGoalCanSubmit = params =>{
    return request.get(`goal/canSubmit`, {
        params: params
    })
}
// 查询干系人
export const reqGetStakeholders = params =>{
    return request.get(`department/getStakeholders`, {
        params: params
    })
}
export const reqGetStaffStakeholders = params =>{
    return request.get(`userDepartment/getStaffStakeholders`, {
        params: params
    })
}
// 查询自己的组织和下级组织
export const reqGetDepartmentArray = params => {
    return request.get(`/department/getDepartmentArray`, {
        params: params
    })
};
// 新增目标
export const reqAddGoal = params =>{
    return request.post(`goal/add`,params)
}
// 目标提审
export const reqSubmitGoal = params =>{
    return request.post(`goal/submit`,params)
}
// 根据id查看目标
export const reqGetGoalById = params => {
    return request.get(`goal/getGoalById`, {
        params: params
    })
};
// 根据目标id查询审批进度
export const reqGetGoalVerify = params => {
    return request.get(`goalApprovalHistory/getGoalVerify`, {
        params: params
    })
};
// 根据目标id查询目标
export const reqGetGoalByParentId = params => {
    return request.get(`goal/getGoalByParentId`, {
        params: params
    })
};
// 删除目标
export const reqDeleteGoal = params => {
    return request.delete(`/goal/delete/` + params.id, params)
};
// 编辑目标
export const reqEditGoal = params =>{
    return request.post(`goal/edit`,params)
}
// 获取共享池分页管理
export const reqGetSharePosition = params =>{
    return request.get(`position/sharePage`, {
        params: params
    })
}
// 加入共享池
export const reqJoinSharePosition = params =>{
    return request.post(`positionConsultant/joinSharePosition`,params)
}
// 获取未读消息数量
export const getCountOfUnreadMessage = params =>{
    return request.get(`messageRecipient/countOfUnreadMessage`, {
        params: params
    })
}
// 获取未处理待办消息
export const getCountOfUnreadBacklog = params =>{
    return request.get(`backlog/countOfUnreadBacklog`, {
        params: params
    })
}
// 目标变更
export const reqChangeGoal = params =>{
    return request.post(`goal/change`,params)
}
// 根据sourceId查询推荐信息
export const reqGetOpportunityBySourceId = params => {
    return request.get(`opportunity/getOpportunityBySourceId`, {
        params: params
    })
};
// 根据sourceId查询目标信息
export const reqGetGoalBySourceId = params => {
    return request.get(`goal/getGoalBySourceId`, {
        params: params
    })
};
// 根据sourceId查询干系人信息
export const reqGetGoalListBySourceId = params => {
    return request.get(`goal/getGoalListBySourceId`, {
        params: params
    })
};
// 根据sourceId查询审批进度
export const reqGetGoalVerifyBySourceId = params => {
    return request.get(`goalApprovalHistory/getGoalVerifyBySourceId`, {
        params: params
    })
};
// 通过sourceId获取录入成本审批进度
export const reqGetEnterCostScheduleBySourceId = params => {
    return request.get(`opportunityApprovalHistory/reqGetEnterCostScheduleBySourceId`, {
        params: params
    })
};
// 通过sourceId获取录入业绩审批进度
export const reqGetEnterPerformanceScheduleBySourceId = params => {
    return request.get(`opportunityApprovalHistory/reqGetEnterPerformanceScheduleBySourceId`, {
        params: params
    })
};
// 获取所有下属id列表
export const reqGetSubordinateIds = params =>{
    return request.get(`user/subordinate`, {
        params: params
    })
}
export const reqGetGoalHistoryById = params => {
    return request.get(`goalApprovalHistory/getGoalHistoryById`, {
        params: params
    })
};
export const reqGetDeliveryHistoryById = params => {
    return request.get(`deliveryApprovalHistory/getDeliveryHistoryById`, {
        params: params
    })
};
export const reqGetOpportunityHistoryById = params => {
    return request.get(`opportunityApprovalHistory/getOpportunityHistoryById`, {
        params: params
    })
};
export const reqGetContactListByCustomerId = params => {
    return request.get(`contact/getContactListByCustomerId`, {
        params: params
    })
};
// 跟进记录
export const reqAddFollowUp = params =>{
    return request.post(`/followUp/add`,params)
};
// 跟进记录列表
export const reqGetFollowUpList = params => {
    return request.get(`followUp/getFollowUpList`, {
        params: params
    })
};
// 职位管理报表分页查询
export const reqGetCustomerReport = params => {
    return request.post(`customer/reportPage`, params)
}
// 客户管理报表导出
export const reqExportCustomerReport = params => {
    return request.post(`customer/reportExport`, params, {responseType: 'blob'})
}
// 统计报表 - 销售商机分页查询
export const reqGetOpportunityReport = params => {
    return request.post(`opportunity/reportPage`, params)
}
// 统计报表 - 销售商机导出
export const reqExportOpportunityReport = params => {
    return request.post(`opportunity/reportExport`, params, {responseType: 'blob'})
}
// 统计报表 - 简历数据分页查询
export const reqGetResumeReport = params => {
    return request.post(`resume/reportPage`, params)
}
// 统计报表 - 简历数据导出
export const reqExportResumeReport = params => {
    return request.post(`resume/reportExport`, params, {responseType: 'blob'})
}
// 统计报表 - 目标达成分页查询
export const reqGetGoalReport = params => {
    return request.post(`goal/reportPage`, params)
}
// 统计报表 - 目标达成导出
export const reqExportGoalReport = params => {
    return request.post(`goal/reportExport`, params, {responseType: 'blob'})
}
// 统计报表 - 职位交付分页查询
export const reqGetPositionReport = params => {
    return request.post(`position/reportPage`, params)
}
// 统计报表 - 职位交付导出
export const reqExportPositionReport = params => {
    return request.post(`position/reportExport`, params, {responseType: 'blob'})
}
// 数据看板
export const reqGetDashboard = params => {
    return request.get(`/dashboard`, {
        params: params
    })
};
// WeChat管理-分页查询
export const reqGetSlideShow = params =>{
    return request.get(`wcSlideshow/page`, {
        params: params
    })
};
// WeChat管理-新增幻灯
export const reqAddSlideShow = params =>{
    return request.post(`/wcSlideshow/add`,params)
};
// WeChat管理-根据id查看幻灯
export const reqGetSlideShowById = params =>{
    return request.get(`/wcSlideshow/getSlideShowById`, {
        params: params
    })
};
// WeChat管理-编辑幻灯
export const reqEditSlideShow = params =>{
    return request.post(`/wcSlideshow/edit`,params)
};
// WeChat管理-删除幻灯
export const reqDelSlideShow = params => {
    return request.delete(`/wcSlideshow/delete/` + params.id, params)
};
// WeChat管理 - 分页查询简历模板
export const reqGetResumeTemplate = params =>{
    return request.get(`/wcResumeTemplate/page`, {
        params: params
    })
};
// WeChat管理-根据新增简历模板
export const reqAddResumeTemplate = params =>{
    return request.post(`/wcResumeTemplate/add`,params)
};
// WeChat管理-根据id查看简历模板
export const reqGetResumeTemplateById = params =>{
    return request.get(`/wcResumeTemplate/getResumeTemplateById`, {
        params: params
    })
};
// WeChat管理-根据编辑简历模板
export const reqEditResumeTemplate = params =>{
    return request.post(`/wcResumeTemplate/edit`,params)
};
// WeChat管理-根据id删除简历模板
export const reqDelResumeTemplate = params => {
    return request.delete(`/wcResumeTemplate/delete/` + params.id, params)
};
// WeChat管理 - 分页查询企业列表
export const reqGetWcCustomer = params =>{
    return request.get(`/wcCustomer/page`, {
        params: params
    })
};
// WeChat管理-新增企业
export const reqAddWcCustomer = params =>{
    return request.post(`/wcCustomer/add`,params)
};
// WeChat管理-根据id查看企业
export const reqGetWcCustomerById = params =>{
    return request.get(`/wcCustomer/getWcCustomerById`, {
        params: params
    })
};
// WeChat管理-编辑企业
export const reqEditWcCustomer = params =>{
    return request.post(`/wcCustomer/edit`,params)
};
// WeChat管理-删除企业
export const reqDelWcCustomer = params => {
    return request.delete(`/wcCustomer/delete/` + params.id, params)
};
// WeChat管理-根据企业Id查询职位
export const reqGetPositionList = params =>{
    return request.get(`/wcPosition/getWcPositionById`, {
        params: params
    })
};

// WeChat管理-更新职位
export const reqUpdateWcPosition = params =>{
    return request.post(`/wcPosition/update`,params)
};
// WeChat管理-取消推荐职位
export const reqRecommendPosition = params => {
    return request.post(`/wcPosition/recommend`, params)
};
// WeChat管理 - 分页查询分类列表
export const reqGetWcClassify = params =>{
    return request.get(`/wcQuestionClassification/page`, {
        params: params
    })
};
// WeChat管理 - 新增一级分类
export const reqAddParentClass = params =>{
    return request.post(`/wcQuestionClassification/add`,params)
};
// WeChat管理-根据一级分类Id查询二级分类
export const reqGetSecondList = params =>{
    return request.get(`/wcQuestionClassification/getSecondList`, {
        params: params
    })
};

// WeChat管理 - 更新二级分类
export const reqUpdateSecondClass = params =>{
    return request.post(`/wcQuestionClassification/updateSecondClass`,params)
};
// WeChat管理 - 删除二级分类
export const reqDelSecond = params => {
    return request.delete(`/wcQuestionClassification/deleteSecond/` + params.id, params)
};
// WeChat管理 - 编辑一级分类
export const reqEditParentClass = params =>{
    return request.post(`/wcQuestionClassification/edit`,params)
};
// WeChat管理 - 删除二级分类
export const reqDelClass = params => {
    return request.delete(`/wcQuestionClassification/delete/` + params.id, params)
};
// WeChat管理 - 分页查询题目
export const reqGetWcQuestion = params =>{
    return request.get(`/wcQuestion/page`, {
        params: params
    })
};
// WeChat管理 - 查看一级分类
export const reqGetPrimaryClassificationArray = params =>{
    return request.get(`/wcQuestionClassification/getPrimaryClassification`, {
        params: params
    })
};
// WeChat管理 - 新增题目
export const reqAddQuestion = params =>{
    return request.post(`/wcQuestion/add`,params)
};
// WeChat管理 - 根据题目Id查看题目
export const reqGetWcQuestionById = params =>{
    return request.get(`/wcQuestion/getWcQuestionById`, {
        params: params
    })
};
// WeChat管理 - 编辑题目
export const reqEditQuestion = params =>{
    return request.post(`/wcQuestion/edit`,params)
};
// WeChat管理 - 删除题目
export const reqDelQuestion = params => {
    return request.delete(`/wcQuestion/delete/` + params.id, params)
};
// WeChat管理 - 分页查询咨询
export const reqGetInfo = params =>{
    return request.get(`/wcInfo/page`, {
        params: params
    })
};
// WeChat管理 - 新增资讯
export const reqAddInfo = params =>{
    return request.post(`/wcInfo/add`,params)
};
// WeChat管理 - 根据资讯Id查看资讯
export const reqGetInfoById = params =>{
    return request.get(`/wcInfo/getInfoById`, {
        params: params
    })
};
// WeChat管理 - 编辑资讯
export const reqEditInfo = params =>{
    return request.post(`/wcInfo/edit`,params)
};
// WeChat管理 - 删除资讯
export const reqDelInfo = params => {
    return request.delete(`/wcInfo/delete/` + params.id, params)
};
// WeChat管理 - 查询企业列表
export const reqGetCustomerArray = params =>{
    return request.get(`/wcCustomer/getCustomerArray`, {
        params: params
    })
};
// WeChat管理 - 分页查询职位
export const reqGetPositionPage = params =>{
    return request.get(`/wcPosition/page`, {
        params: params
    })
};




