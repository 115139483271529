<template>
    <div class="header">
        <Header>
            <Menu mode="horizontal" theme="dark" active-name="1">
                <div class="layout-logo">
                    <img src="../../assets/images/logo.png" alt="">
                </div>
                <div class="layout-title"></div>
                <div class="layout-nav">
                    <div class="approval-bar" v-if="backlogButton.view">
                        <Tooltip content="审批待办">
                            <Icon type="md-checkmark-circle" @click="goBacklogCenter"/>
                            <sup class="count" v-if="backlogCounts !== null && backlogCounts !== 0">{{ backlogCounts }}</sup>
                        </Tooltip>
                    </div>
                    <div class="message-bar" v-if="messageButton.view">
                        <Tooltip content="消息中心">
                            <Icon type="md-mail" @click="goMessageCenter"/>
                            <sup class="count" v-if="messageCounts !== null && messageCounts !== 0">{{ messageCounts }}</sup>
                        </Tooltip>
                    </div>
                    <MenuItem name="1">
                        <Dropdown trigger="click">
                            您好，{{ username }}
                            <a href="javascript:void(0)">
                                <Icon type="ios-arrow-down"></Icon>
                            </a>
                            <DropdownMenu slot="list">
                                <DropdownItem @click.native="showEditPwdDialog">修改密码</DropdownItem>
                                <DropdownItem @click.native="logout">退出系统</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </MenuItem>
                    <Modal title="修改密码" v-model="editPwdFormVisible" ref="editModal">
                        <Form :model="editPwdForm" ref="editPwdForm" :rules="editPwdFormRules" label-colon
                              :label-width="90">
                            <FormItem label="原密码" prop="oldPwd">
                                <Input type="password" placeholder="请输入" v-model="editPwdForm.oldPwd"/>
                            </FormItem>
                            <FormItem label="新密码" prop="newPwd">
                                <Input type="password" placeholder="请输入" v-model="editPwdForm.newPwd"/>
                            </FormItem>
                            <FormItem label="确认新密码" prop="confirmPwd">
                                <Input type="password" placeholder="请输入" v-model.trim="editPwdForm.confirmPwd"/>
                            </FormItem>
                        </Form>
                        <div slot="footer">
                            <Button type="default" @click="editPwdFormVisible=false">取消</Button>
                            <Button type="primary" @click="editPwdSubmit" :loading="saveLoading">确认</Button>
                        </div>
                    </Modal>
                </div>
            </Menu>
        </Header>
    </div>
</template>
<script>
import permission from '@/common/permission'
import {updateUserPwd} from '../../api/system-api'
import {getCountOfUnreadBacklog, getCountOfUnreadMessage} from "@/api/admin-api";

export default {
    data() {
        return {
            backlogButton: {view: false},
            messageButton: {view: false},
            editPwdForm: {
                oldPwd: '',
                newPwd: '',
                confirmPwd: ''
            },
            backlogCounts: null,
            messageCounts: null,
            editPwdFormVisible: false,
            saveLoading: false,
            editPwdFormRules: {
                oldPwd: [
                    {required: true, message: '请输入新密码', trigger: 'blur'},
                ],
                newPwd: [
                    {required: true, message: '请输入新密码', trigger: 'blur'},
                    {
                        pattern: /^(?![\d]+$)(?![a-zA-Z]+$)(?![!#$%^&*]+$)[\da-zA-Z!#$%^&*]{6,16}$/,
                        message: '密码由字母和数字组成6到16位',
                        trigger: 'blur'
                    },
                ],
                confirmPwd: [{
                    required: true,
                    trigger: 'blur',
                    validator: (rule, value, callback) => {
                        if (!value) {
                            return callback(new Error('请再次输入新密码'));
                        }
                        let newPwd = (!this.editPwdForm.newPwd || this.editPwdForm.newPwd == '') ? null : this.editPwdForm.newPwd;
                        let confirmPwd = (!this.editPwdForm.confirmPwd || this.editPwdForm.confirmPwd == '') ? null : this.editPwdForm.confirmPwd;
                        if (newPwd != null && newPwd !== confirmPwd) {
                            callback(new Error('两次密码不一致！'));
                        } else {
                            callback();
                        }
                    }
                }]
            },
            username: ''
        }
    },
    methods: {
        // base64加密开始
        encode64(input) {
            let keyStr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
            let output = "";
            let chr1, chr2, chr3 = "";
            let enc1, enc2, enc3, enc4 = "";
            let i = 0;
            do {
                chr1 = input.charCodeAt(i++);
                chr2 = input.charCodeAt(i++);
                chr3 = input.charCodeAt(i++);
                enc1 = chr1 >> 2;
                enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
                enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
                enc4 = chr3 & 63;
                if (isNaN(chr2)) {
                    enc3 = enc4 = 64;
                } else if (isNaN(chr3)) {
                    enc4 = 64;
                }
                output = output + keyStr.charAt(enc1) + keyStr.charAt(enc2)
                    + keyStr.charAt(enc3) + keyStr.charAt(enc4);
                chr1 = chr2 = chr3 = "";
                enc1 = enc2 = enc3 = enc4 = "";
            } while (i < input.length);
            return output;
        },
        //退出
        logout() {
            this.$Modal.confirm({
                title: ' ',
                content: '确定退出系统?',
                width: '240',
                onOk: () => {
                    this.$router.push('/login');
                    sessionStorage.removeItem('un');
                    sessionStorage.removeItem('Authorization');
                }
            });
        },
        //显示修改密码界面
        showEditPwdDialog: function () {
            this.editPwdFormVisible = true;
            this.$refs['editPwdForm'].resetFields();
        },
        //修改密码
        editPwdSubmit() {
            this.$refs['editPwdForm'].validate((valid) => {
                if (valid) {
                    this.saveLoading = true;
                    let oldPwd = this.encode64(this.encode64(this.editPwdForm.oldPwd) + 'oldPwd');
                    let newPwd = this.encode64(this.encode64(this.editPwdForm.newPwd) + 'newPwd');
                    let confirmPwd = this.encode64(this.encode64(this.editPwdForm.confirmPwd) + 'confirmPwd');
                    updateUserPwd({
                        token: this.token,
                        oldPwd: oldPwd,
                        newPwd: newPwd,
                        confirmPwd: confirmPwd
                    }).then((res) => {
                        this.saveLoading = false;
                        if (res.data.code === 7) {
                            this.$Message.warning(res.data.msg);
                            this.editPwdForm.oldPwd = '';
                            this.editPwdForm.newPwd = '';
                            this.editPwdForm.confirmPwd = '';
                        } else if (res.data.data === 5) {
                            this.$Message.success(res.data.msg);
                            //修改密码后重新登录
                            this.$router.push('/login');
                            sessionStorage.removeItem('Authorization');
                        } else if (res.data.code === 2) {
                            this.$Message.warning(res.data.msg);
                            this.editPwdForm.newPwd = '';
                            this.editPwdForm.confirmPwd = '';
                        } else {
                            this.$Message.error(res.data.msg);
                            this.editPwdForm.oldPwd = '';
                            this.editPwdForm.newPwd = '';
                            this.editPwdForm.confirmPwd = '';
                        }
                    });
                }
            });
        },
        countOfUnreadMessage() {
            getCountOfUnreadMessage().then((res) => {
                if (res.data.code === 1){
                    this.messageCounts = res.data.data
                }else {
                    this.messageCounts = null
                }
            }).catch((err)=>{
                this.messageCounts = null;
            });
        },
        countOfUnreadBacklog() {
            getCountOfUnreadBacklog().then((res) => {
                if (res.data.code === 1){
                    this.backlogCounts = res.data.data
                }else {
                    this.backlogCounts = null
                }
            }).catch((err)=>{
                this.backlogCounts = null;
            });
        },
        goMessageCenter(){
            this.$router.push({path: '/message/list', query: {permissionId: 150}}).catch(()=>{})
        },
        goBacklogCenter(){
            this.$router.push({path: '/backlog/list', query: {permissionId: 170}}).catch(()=>{})
        },
        closeModal(val) {
            this.$emit('input', val);
        }
    },
    mounted() {
        permission.getPermissionButton(170, this.backlogButton);
        permission.getPermissionButton(150, this.messageButton);

        let that = this;
        that.isLogon = sessionStorage.getItem('Authorization') ? true : false;
        that.username = sessionStorage.getItem('un');
        if (that.isLogon) {
            that.countOfUnreadMessage();
            that.countOfUnreadBacklog();
            that._timer = setInterval(function() {
                that.countOfUnreadMessage();
                that.countOfUnreadBacklog();
            }, 1000*60);
        }
    },
    beforeDestroy() {
        if (this._timer) {
            clearInterval(this._timer);
        }
    }
}
</script>
